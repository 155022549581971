import { isMobile } from "../utilities/mobile-detector";
import { stimulusImport } from "../utilities/stimulus";
import {
  addSessionStorageChangeListener,
  removeSessionStorageItem,
  setSessionStorageItem,
} from "../utilities/storage";
import { truncate } from "../utilities/truncate";
import { initMedal,isConnect, modalConnect, modalDisconnect } from "../utilities/web3modal";

const { Controller } = await stimulusImport();

export default class extends Controller<HTMLDivElement> {
  static targets = ["button", "connected", "address", "dropdownContent"];
  declare readonly button: HTMLButtonElement;
  declare readonly connected: HTMLDivElement;
  declare readonly dropdownContent: HTMLDivElement;
  declare readonly address: HTMLParagraphElement;

  private connectStatus(address: string) {
    
    if (address) {
      this.buttonTarget.hidden = true;
      this.connectedTarget.hidden = false;
      this.addressTarget.innerText = truncate(address);
      this.connectStatusMobile(address)
    } else {
      this.buttonTarget.hidden = false;
      this.connectedTarget.hidden = true;
      this.addressTarget.innerText = "";
      this.connectStatusMobile(address)
    }
  }

  private connectStatusMobile(address: string) {
    
    const buttons = document.querySelectorAll("[data-header-target='button']");
    const connected = document.querySelectorAll("[data-header-target='connected']");
    if (!buttons[1] || !connected[1]) {
      return
    }
    const btn = buttons[1] as HTMLButtonElement
    const ctd = connected[1] as HTMLButtonElement
    const p = ctd.querySelector('p');
    if (address) {
      btn.hidden = true;
      ctd.hidden = false;
      p && (p.innerText = truncate(address));
    } else {
      btn.hidden = false;
      ctd.hidden = true;
      p && (p.innerText = '');
    }
  }

  private isEthereumDefined() {
    return typeof window.ethereum !== "undefined";
  }

  private getUserAddress() {
    const user = sessionStorage.getItem("user");
    const { address } = JSON.parse(user ?? "{}");
    return address;
  }

  private handleSessionStorageChange = () => {
    this.connectStatus(this.getUserAddress());
  };

  async request() {
    if(isMobile()){
      this.connectMobile()
      return
    }
    if (this.isEthereumDefined()) {
      const accounts = await window.ethereum?.request({
        method: "eth_requestAccounts",
        params: []
      });
      const account = accounts[0];
      const user = {
        address: account,
      };
      setSessionStorageItem("user", JSON.stringify(user));
      this.connectStatus(this.getUserAddress());
    } else {
      const modal = document.getElementById(
        "metamaskWarning"
      ) as HTMLDivElement;
      modal.hidden = false;

    }
  }

  connectMobile() {
    modalConnect()
  }

  logout() {
    this.connectStatus("");
    removeSessionStorageItem("user");
    modalDisconnect();
  }

  open() {
    this.dropdownContentTarget.hidden = false;
    this.dropdownContentTarget.classList.remove("animate-fade-out-up");
    this.dropdownContentTarget.classList.add("animate-fade-in-down");
  }

  close() {
    this.dropdownContentTarget.classList.remove("animate-fade-in-down");
    this.dropdownContentTarget.classList.add("animate-fade-out-up");
    this.dropdownContentTarget.addEventListener(
      "animationend",
      () => {
        this.dropdownContentTarget.hidden = true;
      },
      { once: true }
    );
  }

  connect() {
    initMedal();
    if (this.isEthereumDefined()) {
      addSessionStorageChangeListener(this.handleSessionStorageChange);
      if (isConnect()||this.getUserAddress()) {
        this.connectStatus(this.getUserAddress());
      }
    }else if(isMobile()){
      addSessionStorageChangeListener(this.handleSessionStorageChange);
      this.connectStatus(this.getUserAddress());
    }
  }
}
