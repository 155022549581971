export async function stimulusImport() {
  let stimulus;
  try {
    stimulus = import.meta.env.DEV
      ? await import("@hotwired/stimulus")
      : await import("https://cdn.skypack.dev/@hotwired/stimulus");
  } catch (error) {
    stimulus = await import("@hotwired/stimulus");
  }
  return stimulus;
}
